"use-strict";

import React from "react";
import { HelmetProvider } from "react-helmet-async";

import "./assets/styles/main.scss";

export const wrapRootElement = ({ element }) => (
  <HelmetProvider>{element}</HelmetProvider>
);
