module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"facebookPixel":{"pixelId":"1059735697888969"},"googleAnalytics":{"trackingId":"UA-129285317-2"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#fff","display":"minimal-ui","icon":"/opt/build/repo/assets/media/logo.png","name":"HYH Studio","short_name":"HYH","start_url":"/","theme_color":"#000","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"863bb29ace6d8fce8c058f796db0eeec"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/build/repo/src/templates/default/DefaultTemplate.tsx"},"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":["gatsby-remark-copy-linked-files",{"resolve":"gatsby-remark-images","options":{"maxWidth":1280,"linkImagesToOriginal":false}}],"remarkPlugins":[null],"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
